import React from 'react';

const Four04 = () => {
    return (
        <div>

            <div className="container text-center" style={{ height: '70vh', paddingTop: "100px" }}>
                <h1>404 Page not Found</h1>
            </div>

        </div>
    );
};

export default Four04;