import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Banner.css'

const Banner = () => {

    return (
        <>
            <div className="container-fluid banner__main__wrapper">
                <div className="container">
                    <div className="row banner__text__wrapper">
                        <div className="col-12 banner__text" >
                            <p className='banner__top__header' data-aos="fade-up"
                                data-aos-duration="3000"> INTRODUCING <span>STRIXBOARD</span></p>
                            <p className='banner__small__header' data-aos="fade-up"
                                data-aos-duration="3000" data-aos-delay="500" >Struggling to stay on top of projects? Meet Strixboard, the powerful project management tool that empowers you to organize ideas, streamline your workflow, and finally conquer your to-do list. Buy it once, own it forever - ditch the subscription headaches and unlock limitless productivity with Strixboard.</p>
                            <br />
                            <Link to="/purchase">
                                <button className='bannerButton' data-aos="fade-up"
                                    data-aos-duration="3000" data-aos-delay="1000">Software Purchase</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banner;
