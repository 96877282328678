import React from 'react';
import Slider from 'react-slick/lib/slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Review.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faQuoteLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import Taqi from '../Assets/taqi.jpg';
import Fatumata from '../Assets/fatumata.jpg';
import Jamie from '../Assets/jamie.jpg';


const Review = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <div className="reviewWrapper" >
                <div className="container">
                    <div className="row justify-content-center align-items-center" data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom">
                        <div className="col-12 text-center">
                            <p className="smallHeading"> <FontAwesomeIcon icon={faBookmark} /> What our customers said about us</p>
                            <p className="largeHeading">Our Customer Reviews</p>
                        </div>
                        <div className="col-lg-7 col-12 sliderWrapper">
                            <div>
                                <Slider {...settings}>
                                    <div className='sliderContainer'>
                                        <div className="card text-center ">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <img className='reviewImg' src={Jamie} alt="" srcset="" />
                                            <p className="reviewDescripttion">Strixboard has become essential in my workflow! I can't imagine working on a project without it.</p>
                                            <p className='reviewName'>Jamie</p>
                                            <span className='reviewStar'><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} /></span>
                                        </div>
                                    </div>
                                    <div className='sliderContainer'>
                                        <div className="card text-center">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <img className='reviewImg' src={Fatumata} alt="" srcset="" />
                                            <p className="reviewDescripttion">I felt safe purchasing the app thanks to the 14 day guarantee and I can gladly say I don't regret my purchase at all!</p>
                                            <p className='reviewName'>Fatumata</p>
                                            <span className='reviewStar'><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} /></span>
                                        </div>
                                    </div>
                                    <div className='sliderContainer'>
                                        <div className="card text-center">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <img className='reviewImg' src={Taqi} alt="" srcset="" />
                                            <p className="reviewDescripttion">Strixboard is amazing! It’s easy to use and has made my project management so much simpler. I highly recommend it!</p>
                                            <p className='reviewName'>Taqi</p>
                                            <span className='reviewStar'><FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} /></span>

                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;