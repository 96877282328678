import './Service.css'
import React, { useEffect, useState } from 'react';
import { faBookmark, faShieldHalved, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const Service = () => {

    const [data, setData] = useState([])
    const navigate = useNavigate()

    const redirect = () => {
        navigate('/checkout')
    }

    useEffect(() => {
        fetch('Data/services.json')
            .then(res => res.json())
            .then(data => setData(data))
    }, [])

    console.log(data);
    return (
        <div className="container-fluid service__section">
            <div className='services__great__wrapper' id='services'>
                <div className="container">
                    <div className="row justify-content-center gy-5">
                        <div className="col-12 text-center">
                            <p className="smallHeading"> <FontAwesomeIcon icon={faBookmark} /> Why we are the best for you?</p>
                            <p className="largeHeading">We provide best services</p>
                        </div>
                        {
                            data.map(({ id, Title, description }) => {
                                return (

                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className={`card rounded-lg serviceCard service_${id}`} data-aos="fade-up"
                                            data-aos-anchor-placement="center-bottom">
                                            <div className="card-body">
                                                <div className="cardBodyWrapper">
                                                    <p className='service__title  text-center'>{Title}</p>
                                                    <p className='service__description text-center'>
                                                        {
                                                            description.length > 250 ?
                                                                description.slice(0, 150).concat("...") :
                                                                description
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;