import { useEffect } from "react"
import "aos/dist/aos.css";
import AOS from "aos";
import { Route, Routes } from "react-router-dom"
import Banner from "./Components/Banner/Banner"
import Header from "./Components/Header/Header"
import Service from "./Components/Services/Service";
import Footer from "./Components/Footer/Footer";
import Review from "./Components/Review/Review";
import Login from "./Components/Authentication/Login/Login";
import RequireAuth from "./Components/Authentication/Login/RequireAuth/RequireAuth";
import Four04 from "./Components/404page/Four04";
import Purchase from "./Components/Purchase/Purchase";

function App() {

	useEffect(() => {
		AOS.init();
	}, [])

	return (
		<>
			<Header />
			<Routes>
				<Route path="/" element={
					<>
						<Banner />
						<Service />
						<Review />
					</>
				} />
				<Route path="/login" element={<Login />} />
				<Route path="/purchase" element={
					<RequireAuth>
						<Purchase />
					</RequireAuth>
				} />
				<Route path="*" element={<Four04 />} />
			</Routes>
			<Footer />
		</>
	)
}

export default App
