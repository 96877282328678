import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase.init';
import { doc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';

const Purchase = () => {
  const [userData, setUserData] = useState(null);
  const [paypalScriptLoaded, setPaypalScriptLoaded] = useState(false);

  useEffect(() => {
    const fetchUserDocument = async () => {
      try {
        const docRef = doc(db, 'users', auth.currentUser.uid);

        const unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            const userDataFromFirestore = docSnap.data();
            setUserData({
              ...userDataFromFirestore,
              purchase: userDataFromFirestore.purchase || false
            });
            if (!userDataFromFirestore.purchase) { setPaypalScriptLoaded(false); }

          } else {
            console.log('No such document!');
          }
        });

      } catch (error) {
        console.error('Error fetching user document:', error);
      }
    };

    fetchUserDocument();
  }, []);

  const loadPayPalScript = () => {
    if (!paypalScriptLoaded) {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_paypal_clientID}&disable-funding=paylater,card`;
      script.async = true;
      script.onload = () => {
        setPaypalScriptLoaded(true);
        window.paypal.Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: '5'
                }
              }]
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(async details => {
              alert('Transaction completed by ' + details.payer.name.given_name);

              const transactionRef = doc(db, 'transactions', auth.currentUser.uid);
              const updateData = {
                [`${new Date()}`]: {
                  orderID: data.orderID,
                  payerID: data.payerID,
                  paymentID: data.paymentID,
                  amount: details.purchase_units[0].amount.value,
                  currency: details.purchase_units[0].amount.currency_code,
                  status: details.status,
                  payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                  payerEmail: details.payer.email_address,
                  transactionTime: new Date()
                }
              };
              const userDocRef = doc(db, 'users', auth.currentUser.uid);
              return await updateDoc(userDocRef, { purchase: true })
                .then(() => {
                  return setDoc(transactionRef, updateData, { merge: true });
                })
                .then(() => {
                  console.log('Transaction saved successfully');
                })
                .catch((error) => {
                  console.error('Error updating purchase status or saving transaction:', error);
                });
            });
          },
          onCancel: async (data) => {
            console.log('Payment cancelled:', data);
            alert('Payment was cancelled');

            const cancellationRef = doc(db, 'cancellations', auth.currentUser.uid);
            const updateData = {
              [`${new Date()}`]: {
                orderID: data.orderID,
              }
            };

            return setDoc(cancellationRef, updateData, { merge: true })
              .then(() => {
                console.log('Cancellation saved successfully');
              })
              .catch((error) => {
                console.error('Error saving cancellation: ', error);
                console.log('Cancellation data not saved:', data);
              });
          },

          onError: (err) => {
            console.error('An error occurred during the transaction:', err);
            alert('An error occurred during the transaction. Please try again.');

            const errorRef = doc(db, 'errors', auth.currentUser.uid);
            const updateData = {
              [`${new Date()}`]: {
                error: err.toString(),
                errorTime: new Date(),
              }
            };

            updateDoc(errorRef, updateData)
              .then(() => {
                console.log('Error saved successfully');
              }).catch((error) => {
                console.error('Error saving error details: ', error);
              });
          },

        }).render('#paypal-button-container');
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    if (userData && !userData.purchase) {
      loadPayPalScript();
    }
  }, [userData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '70vh' }}>
          <h3 className="card-title text-center mb-4" style={{ color: '#0a4ab0' }}>Purchase Strixboard</h3>
          <h6 className="card-subtitle mb-4 text-muted text-center">No subscriptions. Buy once, own forever.</h6>
          <div className="card border-primary shadow mb-3" style={{ maxWidth: '400px', width: '100%' }}>
            <h4 className="card-header bg-primary text-white text-center">
              Features
            </h4>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><i className="fas fa-infinity me-2"></i> Unlimited board space</li>
                <li className="list-group-item"><i className="fas fa-tools me-2"></i> Fast and efficient tools</li>
                <li className="list-group-item"><i className="fas fa-history me-2"></i> Free lifetime updates</li>
                <li className="list-group-item"><i className="fas fa-mouse-pointer me-2"></i> Drag and drop functionality</li>
                <li className="list-group-item"><i className="fas fa-users me-2"></i> Seamless collaboration with team members</li>
                <li className="list-group-item"><i className="fas fa-paint-brush me-2"></i> Customizable templates and boards</li>
                <li className="list-group-item"><i className="fas fa-shield-alt me-2"></i> Our guarantee</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
          <div style={{ maxWidth: '400px', width: '100%' }}>
            <h4>
              Our Guarantee
            </h4>
            <p>
              Your satisfaction is our priority. Enjoy a 14-day money-back guarantee with every purchase. If you're not satisfied, get a full refund within 14 days of your purchase. Just email our support team.
            </p>
          </div>
          {userData && userData.purchase ? (
            <h4 style={{ maxWidth: '400px', width: '100%', color: '#0d6efd' }}>You have already purchased the subscription.</h4>
          ) : (
            <div id="paypal-button-container" style={{ maxWidth: '400px', width: '100%' }}></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Purchase;
