import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare, faKey } from '@fortawesome/free-solid-svg-icons';
import gmail from '../../Assets/google.png';
import './Login.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import {
  useCreateUserWithEmailAndPassword,
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
  useSendPasswordResetEmail
} from 'react-firebase-hooks/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db, auth } from '../../../firebase.init';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const Login = () => {
  const [toggle, setToggle] = useState(false);
  const [userError, setuserError] = useState(null);
  const toastId = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  const [signInWithEmailAndPassword, signInuser, signInloading, signInerror] = useSignInWithEmailAndPassword(auth);
  const [createUserWithEmailAndPassword, user, loading, error] = useCreateUserWithEmailAndPassword(auth);
  const [signInWithGoogle, Googleloading, Googleerror] = useSignInWithGoogle(auth);
  const [sendPasswordResetEmail, sending, ResetPassworderror] = useSendPasswordResetEmail(auth);

  const saveUserInfoToFirestore = async (authUser) => {
    try {
      if (authUser) {
        const userDocRef = doc(db, 'users', authUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        let purchaseStatus = false;

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if ('purchase' in userData) {
            purchaseStatus = userData.purchase;
          }
        }

        await setDoc(userDocRef, {
          uid: authUser.uid,
          name: authUser.displayName || 'Anonymous',
          email: authUser.email,
          photoURL: authUser.photoURL || '',
          lastLoginAt: authUser.metadata.lastSignInTime,
          creationTime: authUser.metadata.creationTime,
          phoneNumber: authUser.phoneNumber || '',
          purchase: purchaseStatus,
        }, { merge: true });
        console.log('User info saved successfully');
      }
    } catch (error) {
      console.error('Error saving user info to Firestore:', error);
    }
  };

  const handleAuthStateChange = (authUser) => {
    if (authUser) {
      saveUserInfoToFirestore(authUser);
      setuserError("");
      navigate(from, { replace: true });
    }
  };

  const signUp = async (e) => {
    if (!email) {
      return;
    } else if (!password) {
      return;
    }
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(email, password);
      handleAuthStateChange(userCredential.user);
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  const gmailLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithGoogle();
      handleAuthStateChange(userCredential.user);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };


  if (error || signInerror || Googleerror) {
    toast.error('Wrong Credentials', {
      toastId: 'Something'
    });
  } else if (sending) {
    toast.info('Sending Email...', {
      toastId: 'Email'
    });
  } else if (ResetPassworderror) {
    toast.info('User doesn\'t exist...', {
      toastId: 'UserDE'
    });
  } else if (loading || signInloading || Googleloading) {
    toast.info('Please Wait...', {
      toastId: 'Please'
    });
  }

  const toggleSignIn = () => {
    setToggle(!toggle);
  };

  const signIn = async (e) => {
    if (!email) {
      return;
    } else if (!password) {
      return;
    }
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(email, password);
      handleAuthStateChange(userCredential.user);
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const resetpassword = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.info('Enter Email Address...', {
        toastId: 'EmailEA'
      });
    } else {
      try {
        await sendPasswordResetEmail(email);
        toast.info('Sending Email...', {
          toastId: 'Email'
        });
      } catch (error) {
        console.error('error', error);
        if (error.code === 'auth/user-not-found') {
          toast.info('User doesn\'t exist...', {
            toastId: 'UserDE'
          });
        } else {
          console.error('Error sending password reset email:', error);
          toast.error('Failed to send password reset email', {
            toastId: 'ResetError'
          });
        }
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="loginWrapper">

          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-7">
              <div className="card loginCard">
                <br />
                <p className="loginTitle">{toggle ? 'Sign Up ' : 'Login'}</p>
                <div className="card-body">

                  <form action="">

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <FontAwesomeIcon icon={faEnvelopeSquare} />
                        </span>
                      </div>
                      <input type="email" required class="form-control" placeholder="Email" aria-label="Email"
                        onChange={(e) => {
                          e.preventDefault()
                          setEmail(e.target.value);

                        }}

                      />
                    </div>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <FontAwesomeIcon icon={faKey} />
                        </span>
                      </div>
                      <input type="password" required class="form-control" placeholder="Password" aria-label="Email"
                        onChange={(e) => {
                          e.preventDefault()
                          setPassword(e.target.value);

                        }}
                      />
                    </div>


                    <div class="input-group mb-3 ">

                      <input className='signInButton ' type="submit" value={toggle ? "Sign Up" : 'Sign in'}
                        onClick={
                          toggle ? signUp : signIn
                        }
                      />
                      <p className="my-3 h6 text-center text-danger text-center mx-auto">{userError}</p>
                    </div>


                    <div className="row justify-content-center text-center">
                      <div className="col-12">
                        <p> {
                          toggle ? <>

                            Already Signed in? < Link onClick={toggleSignIn} to={""}>Sign in here!</Link>
                          </>
                            :
                            <>
                              Don't remember your password? < Link onClick={resetpassword} to={""}>Reset Password now!</Link>

                            </>
                        } </p>
                      </div>

                      <div className="col-12">
                        <div className="gmailLogin">
                          <div className="orLine">
                            <hr className="line" />
                            <span className="orText">OR Login with</span>
                            <hr className="line" />
                          </div>
                          <br />
                          <button className='Gmail' onClick={gmailLogin}>
                            <span> <img className='w-25' src={gmail} alt="" srcset="" /> Google </span>
                          </button>
                        </div>
                      </div>


                      <div className="row justify-content-center text-center">
                        <div className="col-12">
                          <p> {
                            toggle ? <>
                            </>
                              :
                              <>
                                <br /> First time visiting StrixBoard? < Link onClick={toggleSignIn} to={""}>Sign up here!</Link><br />
                              </>
                          } </p>
                        </div>
                        <ToastContainer autoClose={1000} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Login;