import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMailBulk, faAddressCard, faHome, } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import logo from '../Assets/logo.PNG'
import './Footer.css'
import { Link } from 'react-router-dom';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';

const Footer = () => {
    return (
        <div className='container-fluid footerWrapper'>
            <div className="container" data-aos="fade-up"
                data-aos-anchor-placement="center-bottom">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-3 col-md-6 col-12">
                        <p className="footerSectionTitle Logo">
                            <img src={logo} alt="" srcset="" />
                        </p>
                        <p className='footerTitle'>Empower your productivity with Strixboard: where organizing ideas and streamlining workflows culminate in conquering your to-do list, forever. </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <p className="footerSectionTitle">Navigation</p>
                        <ul>
                            <li><FontAwesomeIcon icon={faHome} /> <Link to={'/'}>Home</Link>  </li>
                            <li><FontAwesomeIcon icon={faShoppingCart} /> <Link to={'/purchase'}>Purchase</Link>  </li>
                            <li><FontAwesomeIcon icon={faShoppingCart} /> <Link to={'/purchase'}>Purchase</Link>  </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <p className="footerSectionTitle">Contact Information</p>
                        <ul>
                            <li><FontAwesomeIcon icon={faPhone} /> +102-374-393-7783</li>
                            <li><FontAwesomeIcon icon={faMailBulk} /> Johndoe@gmail.com</li>
                            <li><FontAwesomeIcon icon={faAddressCard} /> 132, Kingston, New York 12401</li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="copyright">{`Copyright © ${new Date().getFullYear()} StrixBoard`}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;